<!-- slot width height 一屏内即可，质量要求可用 :scale="2" 放大无需再@bei: 2px;width: @bei*600 -->
<template>
    <div>
        <van-overlay :show="value" class="com-newshare" :lock-scroll="false" @click="ck">
            <div class="cn-pic">
                <div v-if="miniSharePoster" class="mini-share-poster-text">
                    <template v-if="params.image">
                        长按海报可分享给好友~
                        <img @click="closeConfirm()" class="result-close"
                            src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png"
                            alt="">
                    </template>
                </div>
                <div class="cn-img" :class="{ mt: !miniSharePoster }">
                    <img :src="params.image" v-if="params.image" />

                </div>
            </div>

            <div class="cn-sub" v-if="!miniSharePoster">
                <div :class="['cs-list', { 'share-center': center }]" :style="shareStyle">
                    <div class="cl-img" v-for="(item) in list" :key="item.key" @click="shareFn(item)"
                        v-if="isShowItem(item)">
                        <img v-if="item.img" :src="item.img" />
                        <div>{{ item.text }}</div>
                    </div>
                </div>
                <div class="cs-cancle-btn" @click="closeConfirm()">取消</div>
            </div>
        </van-overlay>
        <van-overlay :show="isShareWx" :z-index="1500" @click="isShareWx = false">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/anniversary2021/share-mini.png"
                    class="tip-img" />
            </div>
        </van-overlay>
        <next-loading :zIndex="2500" :loadStatus="nextLoadingOptions.loadStatus"
            :loadingTxt="nextLoadingOptions.loadingTxt"></next-loading>
        <div class="cn-slot" :class="{ isdev: dev }" ref="refDom">
            <slot name="createDom" />
        </div>
    </div>
</template>
<script>
import { webAppShare, saveCreateImg, appType } from '@/lib/appMethod'
import nextLoading from "@/components/loading/loading";
import html2canvas from 'html2canvas';
const datalist = [
    {
        scene: 0,
        img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/001.png',
        text: "微信好友",
        type: '2',
        key: 'posterfriend'
    },
    {
        scene: 1,
        img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/002.png',
        text: "朋友圈",
        type: '2',
        key: 'postermessage'
    },
    {
        scene: '',
        img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/005.png',
        text: "保存",
        type: '',
        key: 'save'
    },
    {
        scene: 0,
        img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/001.png',
        text: "微信好友",
        type: '3',
        key: 'minifriend'
    },
    {
        scene: 1,
        img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/002.png',
        text: "朋友圈",
        type: '3',
        key: 'minimessage'
    },
    {
        scene: 0,
        img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/001.png',
        text: "微信好友",
        type: '1',
        key: 'friend'
    },
    {
        scene: 1,
        img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/002.png',
        text: "朋友圈",
        type: '1',
        key: 'message'
    },
    {
        scene: '',
        img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/003.png',
        text: "生成海报",
        type: '',
        key: 'create'
    }
]
export default {
    props: {
        // 动态分享列表 详见下面 data.itemList
        shareItem: {
            type: Array,
            default: () => {
                return ['minifriend', 'postermessage', 'save']
            }
        },
        reload: { // 可重复生成图片A
            type: Boolean,
            default: false
        },
        // 分享按钮布局，true:居中，false不居中
        center: {
            type: Boolean,
            default: true
        },
        // 分享海报时是否只有保存按钮，教练端暂无分享海报到好友功能
        onlySave: {
            type: Boolean,
            default: false
        },
        // 自定义的分享样式
        shareStyle: {
            type: Object,
            default: () => { },
        },
        // 分享参数，title：标题，content：分享内容，image：图片地址，url：链接url, miniImage:小程序卡片分享图片
        shareParams: {
            type: Object,
            default: () => { }
        },
        // 是否显示
        value: {
            type: Boolean,
            default: false
        },
        // 小程序分享海报，隐藏底部按钮
        miniSharePoster: {
            type: Boolean,
            default: false
        },
        //质量倍数
        scale: {
            type: [Function, Number],
            default: () => window.devicePixelRatio
        },
        //开发专用
        dev: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            params: { // 分享的信息
                url: '',
                image: '',
            },
            isShareWx: false,
            appType: appType(),
            nextLoadingOptions: {
                loadStatus: false,
                loadingTxt: "海报生成中...",
            },
            hasClickCreate: false
        }
    },
    watch: {
        value(val) { // 显示隐藏   
            if (val) {
                if (this.reload) {
                    this.params.image = '';
                    this.hasClickCreate = false;
                }
                // 包含save，表示直接弹起海报的界面
                if (this.shareItem.includes('save')) {
                    this.initPoster()
                }
            } else {
                this.isSharePoster = false
            }
        },
        shareParams: {
            handler(val) {
                if (typeof (val) == 'object') {
                    this.params = JSON.parse(JSON.stringify(val));
                }
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        list() {
            const data = [];
            this.shareItem.map((item) => {
                var value = item;
                if (item == 'create' && this.hasClickCreate) {
                    value = 'save'
                }
                datalist.map((jtem) => {
                    if (jtem.key === value) {
                        data.push(jtem)
                    }
                })
            })
            return data
        }
    },
    components: {
        nextLoading
    },
    methods: {
        ck(e) {
            if (this.miniSharePoster && !this.params.image) {
                this.closeConfirm();
            }
        },
        async initPoster() {
            if (!this.params.image) {
                const canvas = this.$refs.refDom.children[0];
                // const img = canvas.getElementsByTagName('img');
                // await this.$nextTick();
                // const ready = Array.from(img).every(d => {
                //     return d.naturalHeight
                // })
                // if (!ready) {
                //     return;
                // }
                this.nextLoadingOptions.loadStatus = true

                setTimeout(() => {
                    const opts = {
                        scale: typeof this.scale == 'function' ? this.scale(canvas) : this.scale,
                        backgroundColor: 'transparent', // transparent
                        useCORS: true, // 允许跨域
                        height: canvas.offsetHeight,
                        width: canvas.offsetWidth,
                        // windowWidth: document.body.scrollWidth,
                        // windowHeight: document.body.scrollHeight,
                    };console.log(opts);
                    html2canvas(canvas, opts).then((canvas) => {
                        let dataURL = canvas.toDataURL('image/png');
                        this.params.image = dataURL;
                    }).catch().then(() => {
                        this.nextLoadingOptions.loadStatus = false;
                    });
                }, 500)
            }
        },
        isShowItem(item) {
            return this.onlySave ? item.key == 'save' : true
        },
        closeConfirm() {
            this.$emit('close');
        },
        shareFn(item) {
            console.log(this.params)
            if (!this.params.url) {
                this.tips('分享参数不能为空')
                return
            }
            const appTypeStr = this.appType;
            let data = {
                scene: item.scene,
                ...this.params
            }
            switch (item.key) {
                case 'friend': // 分享链接给好友

                    if (['wx', 'mini'].includes(appTypeStr)) {
                        // 提示 点击右上角
                        this.isShareWx = true
                    } else {
                        webAppShare(item.type, data)
                    }

                    break;
                case 'message': // 分享链接到朋友圈

                    if (['wx', 'mini'].includes(appTypeStr)) {
                        // 提示 点击右上角
                        this.isShareWx = true
                    } else {
                        webAppShare(item.type, data)
                    }

                    break;
                case 'create': // 去生成海报
                    if (['wx', 'mini'].includes(appTypeStr)) {
                        this.show = false
                        this.isShareImgWx = true
                    }
                    this.initPoster();
                    this.hasClickCreate = true;
                    break;
                case 'posterfriend': // 分享图片给好友
                    if (!this.params.image) {
                        this.$toast('请先生成海报');
                        return;
                    }
                    webAppShare(item.type, data)
                    break;
                case 'postermessage': // 分享图片到朋友圈
                    if (!this.params.image) {
                        this.$toast('请先生成海报');
                        return;
                    }
                    webAppShare(item.type, data)
                    break;
                case 'save': // 保存图片
                    if (!this.params.image) {
                        this.$toast('请先生成海报');
                        return;
                    }
                    this.savePosterImg();
                    break;
                case 'minifriend': // 分享小程序给好友
                    var miniParams = JSON.parse(JSON.stringify(data))
                    miniParams.image = miniParams.miniImage
                    if (['wx', 'mini'].includes(appTypeStr)) {
                        // 提示 点击右上角
                        this.isShareWx = true
                    } else {
                        webAppShare(item.type, miniParams)
                    }
                    break;
                case 'minimessage': // 分享小程序到朋友圈（暂不可用）

                    break;
            }
            if (item.key !== 'create') {
                this.shareSuccess(item.key)
            }
            // 点击分享按钮  埋点使用
            this.$emit('click-share-item', item.key);
        },
        savePosterImg() {
            saveCreateImg(this.params.image)
        },

        shareSuccess(key) {
            this.isSharePoster = false
            this.$emit('share-success', key)
        },
    }
}
</script>
<style scoped lang="less">
.com-newshare {
    z-index: 1000;
    display: flex;
    flex-direction: column;

    .cn-pic {
        padding: 20px 50px 50px;
        flex: 1;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;

        .mini-share-poster-text {
            font-size: 32px;
            font-weight: bold;
            color: white;
            text-align: center;
            padding-top: 42px;
            margin-bottom: 20px;

            .result-close {
                position: absolute;
                right: 30px;
                top: 50px;
                width: 60px;
                height: 60px;
            }
        }

        .cn-img {
            &.mt {
                padding-top: 80px;
            }

            img {
                width: 100%;
            }
        }
    }

    .cn-sub {
        border-radius: 24px 24px 0px 0px;
        background-color: #fff;
        box-sizing: border-box;
        position: relative;
        padding-bottom: env(safe-area-inset-bottom);

        .share-img {
            width: 180px;
            height: 180px;
            position: absolute;
            right: 10px;
            top: -160px;
        }

        .cs-title {
            padding: 48px 0 0 32px;
            font-size: 32px;
            color: #242831;
            font-weight: bold;
        }

        .cs-list {
            display: flex;
            flex-wrap: wrap;

            .cl-img {
                margin-top: 42px;
                font-size: 24px;
                color: #9aa1a9;
                text-align: center;
                margin-left: 56px;

                &:first-child {
                    margin-left: 24px;
                }

                img {
                    width: 112px;
                    display: block;
                    margin: 0 auto;
                }

                div {
                    margin-top: 8px;
                }
            }
        }

        .share-center {
            justify-content: center;

            .cl-img {
                flex: 1;
                margin-left: 0;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .cs-cancle-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 36px 0;
            font-size: 32px;
            color: #242831;
            box-sizing: border-box;
            border-top: 1px solid #eeeeee;
            margin-top: 48px;
        }

    }

}

.tip-box {
    position: relative;

    .tip-img {
        position: absolute;
        right: 3.8rem;
        top: 1rem;
        width: 300px;
    }
}

.cn-slot {
    position: fixed;
    top: 0;
    left: 200%;
}

.isdev {
    left: 0;
    z-index: 9999;
    overflow: auto;
    max-height: 100vh;
}
</style>